import React, { Component } from 'react';
import Home from './home/home.js';
import Projects from './projects/projects.js';
import Model from './projects/model.js';
import Contact from './contact/contact.js';
//import Shop from './contact/Contact';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
  } from "react-router-dom";


function App() {
    return (
        <Router>
            <Switch>
                <Route path="/contact" component={withRouter(Contact)} />
                <Route path="/projects" component={withRouter(Projects)} />
                <Route path="/personal-website" component={withRouter(Home)} />
                <Route path="/model" component={withRouter(Model)} />
                <Route exact path="/" component={withRouter(Home)} />

            </Switch>
        </Router>
    )
}

export default App;