import React from 'react';
import './App.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PictureFrame from "react-rounded-image";
import resume from './resume.pdf';

var divStyle = {
  marginBottom: 100,
};

var titleStyle = {
  paddingTop: '25vh',
  paddingLeft: '10px',
  paddingRight: '10px'
};

var buttonStyle = {
  marginTop: 15,
  marginLeft: 15,
  marginRight: 15,
  color: '#00A8E8',
  borderColor: '#00A8E8'
};


function TitleBlurb() {
  
    return (
        <div style={divStyle}>
        
        <Typography style={titleStyle}variant="h2" align="center" color="Primary" gutterBottom>
              Daniel
              Di&nbsp;Cesare
          </Typography>
        
        <Typography variant="h5" align="center" color="Secondary" paragraph>
            Software and Biomedical Engineering<br />
            4th Year - McMaster University
        </Typography>

        <div align="center">
        <Button variant="outlined" color="#64ffda" style={buttonStyle} href={"https://github.com/DanDiCesare"}>
            Github
        </Button>
        <Button variant="outlined" color="primary" style={buttonStyle} href={resume}>
            Resume
        </Button>
        </div>
      </div>
    );
  }

export default TitleBlurb;
