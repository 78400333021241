import React from 'react';
import ReactDOM from 'react-dom';
import "@google/model-viewer/dist/model-viewer-legacy"
import { CssBaseline } from '@material-ui/core';
import Astronaut from "./pictures/biomed/Astronaut.glb"
import hipCAD from "./pictures/biomed/finalAssembly.glb"
import AutoScale from 'react-auto-scale';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

let card = {
    height: '800px',
    width: '100%'
  }


function Model() {
  
    return( 
        <CssBaseline>
            <model-viewer style={card} auto-rotate camera-controls src={hipCAD}></model-viewer>
        </CssBaseline>
    )
}

export default Model;
