import React from 'react';
import './App.css';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000709',
    },
    secondary: {
      main: '#B5B9BA',
    },
    initial: {
      main: '#697490'
    }
  },
});

function Copyright() {
    return (
      <ThemeProvider theme={theme}>
      <Typography variant="body2" color="Secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Daniel Di Cesare
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </ThemeProvider>
    );
  }


export default Copyright;
