import React, {Component} from 'react'; 
import {reactStyle, injectStyle} from 'react-atomic-molecule';
import MediaQuery from 'react-responsive';
import SoftwareBanner from './softwareBanner';
import Software from './software';
import Biomed from './biomed';
import Research from './research';
import Scroll from 'react-scroll';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {
    ScrollSpy,
    ScrollReceiver,
    SmoothScrollLink,
    scrollDispatch
} from 'organism-react-scroll-nav';

const software = createMuiTheme({
    palette: {
      primary: {
        main: '#FFFBFB'
      },
      secondary: {
        main: '#d62828',//red
      },
      background: {
        paper: '#005980'
      }
    },
});

const biomed = createMuiTheme({
palette: {
    primary: {
        main: '#FFFBFB'
    },
    secondary: {
        main: '#d62828',//red
    },
    background: {
        paper: '#007EA7'
    }
},
});
const research = createMuiTheme({
    palette: {
        primary: {
            main: '#FFFBFB'
        },
        secondary: {
            main: '#d62828',//red
        },
        background: {
            paper: '#00A8E8'
        }
    },
});

class MenuItem extends Component
{
    render()
    {
        const { targetInfo, style, ...reset } = this.props;
        let activeStyle = null;
        if (targetInfo.active) {
            activeStyle = Styles.active;
        }
        return (
            <React.Fragment>
                <MediaQuery minDeviceWidth={1224}>
                    <div style={{
                    ...Styles.link,
                    ...style,
                    ...activeStyle
                    }} {...reset}  />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1224}>
                <div style={{
                ...Styles.linkMobile,
                ...style,
                ...activeStyle
                }} {...reset}  />
            </MediaQuery>
            </React.Fragment>
        );
    }
}

class ScrollNav extends React.Component
{
    componentDidMount(){
        Scroll.animateScroll.scrollToTop(0);
      }
    render()
    {
    return (
    
    <div>
        
        <div>
            <ScrollSpy href="#t1"id="t1" style={{...Styles.content, background:'#000709'}}>
            <ThemeProvider theme={software}>
                <Software />
                <SoftwareBanner />
            </ThemeProvider>
            </ScrollSpy>
            <ScrollSpy id="t2" style={{...Styles.content, background:'#000709'}}>
            <ThemeProvider theme={biomed}>
                <Biomed />
            </ThemeProvider>
            </ScrollSpy>
            <ScrollSpy id="t3" style={{...Styles.content, background:'#000709'}}>
            <ThemeProvider theme={research}>
                <Research />
            </ThemeProvider>
            </ScrollSpy>
        </div>
        
        <div id="nav-parent" style={Styles.nav}>
            <SmoothScrollLink
                scrollRefId="nav-parent"
                container={<MenuItem />}
                targetId="t1"
                style={{background: software.palette.background.paper, borderRadius: '25px 25px 5px 25px'}}
            >
                Software
            </SmoothScrollLink>
            
            <SmoothScrollLink
                scrollRefId="nav-parent"
                container={<MenuItem />}
                targetId="t2"
                style={{background: biomed.palette.background.paper, borderRadius: '25px 25px 5px 5px'}}
            >
                Biomed
            </SmoothScrollLink>
            
            <SmoothScrollLink
                scrollRefId="nav-parent"
                container={<MenuItem />}
                targetId="t3"
                style={{background: research.palette.background.paper, borderRadius: '25px 25px 25px 5px'}}
            >
                Research
            </SmoothScrollLink>
        </div>
    </div>
    )
    }
}

export default ScrollNav;


const Styles = {
    content: {
        height:"auto",
        minHeight: '100vh',
        textAlign:"center",
        paddingTop: "0%",
        fontSize: "4rem",
        color:"#fff",
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    link: {
        
        display: "inline-block",
        padding: "0.3rem 0.5rem",
        fontSize: "2rem",
        allowFontScaling: 'true',
        color: "#fff",
        marginRight: "10px",
        opacity: "1",
        cursor: "pointer",
    },
    linkMobile: {
        
        display: "inline-block",
        padding: "0.3rem 0.5rem",
        fontSize: "5vw",
        allowFontScaling: 'true',
        color: "#fff",
        marginRight: "10px",
        opacity: "1",
        cursor: "pointer",
    },
    active: {
        opacity: 1
    },
    body: reactStyle({
        margin: 0,
        padding: 0
    }, 'body'),
    nav: {
        position:'fixed',
        background:'transparent',
        textAlign:'center',
        width:"100%",
        paddingBottom: "10px",
        bottom: 0,
        zIndex:1
    }
};
injectStyle();

