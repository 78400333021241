import React from 'react';
import './App.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Scroll from 'react-scroll';
import IconButton from '@material-ui/core/IconButton';


export default class ScrollIcon extends React.Component {

  constructor(props) {
    super(props);
    this.state = { opacity: 0 };
  }

  componentDidMount () {      
    window.onscroll =()=>{
        this.setState({currentScrollHeight: window.scrollY})
   }
 }

  render() {
    const opacity = Math.min(100 / (this.state.currentScrollHeight * 5)  , 1)

      return (
        <div style={{opacity}}>
        <ExpandMoreIcon color='primary' style={{ fontSize: '7vh' }}/>
        </div>
      ); 
  }; // render
} // Landing