import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.js';
import TitleBar from './home/TitleBar';
import TitleBlurb from './home/TitleBlurb';
import Copyright from './home/Copyright';
import Cards from './home/cards';
import HomeBottom from './home/HomeBottom';
import HomeBanner from './home/HomeBanner';

import ScrollIcon from './home/ScrollIcon';
import * as serviceWorker from './home/serviceWorker';
import { CssBaseline, Paper, CardMedia } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import backdrop from './home/lowpoly.png';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#003049',
    },
    secondary: {
      main: '#d62828',
    }
  },
});

theme.typography.h5 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

let divStyle = { 
  backgroundImage:`url(${backdrop})`,
  backgroundSize: 'cover',
  height: '100vh',
  width: '100vw',
  position: 'relative'
};

let arrowStyle = {
  width: '100%',
  height: '50px',
  position: 'absolute',
  bottom: '3vh'
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
