import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CardMedia } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Fade from 'react-reveal/Fade';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

//pictures
import Powifi from './pictures/research/powifi.png';
import climate from './pictures/research/climate.png';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const cards = [
    {
      title: 'Powifi',
      lang: 'Tshark + Python',
      year: '3rd',
      img: Powifi,
      src: "",
      code: "",
      codes: "",
      description: [
      'Targeted wireless charging over WiFi RF signals.'
      ],
    },
    {
      title: 'Climate-Pi',
      lang: 'Python',
      year: '2nd',
      img: climate,
      src: "",
      code: "https://github.com/Beasley-Neighbourhood-Association/climate-pi",
      codes: "Code",
      description: [
      'An open-source project allowing users to display data gathered by raspberry-pi based IoT nodes.'
      ],
    },
  ];

export default function Cards() {
    const classes = useStyles();
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {/* Hero unit */}
          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {cards.map((cards) => (
                <Grid item key={cards.title} xs={12} sm={6} md={4}>
                  <Fade left>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={cards.img}
                      title={cards.title}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {cards.title}
                      </Typography>
                      <Typography component="h2">
                        {cards.lang} | {cards.year} year
                      </Typography>
                      <Typography>
                        {cards.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary" target="_blank" href={cards.code}>
                        {cards.codes}
                      </Button>
                    </CardActions>
                  </Card>
                  </Fade>
                </Grid>   
              ))}
            </Grid>
          </Container>
        </main>
      </React.Fragment>
    );
  }

