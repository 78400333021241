import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00A8E8',
      },
      secondary: {
        main: '#00A8E8',
      },
      initial: {
        main: '#000F14'
      },
      text: {
        primary: '#00A8E8'
      }
    },
  });

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#000709'
  },
});


export default function TitleBar() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Button fullWidth href="/">
          <ListItem button key={"Home"}>
            <ListItemIcon><HomeIcon color="Secondary"/></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Button>

        <Button fullWidth href="/projects#t1">
          <ListItem button key={"Projects"}>
            <ListItemIcon><CodeIcon color="Secondary"/></ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
        </Button>

        <Button fullWidth href="/contact">
          <ListItem button key={"Contact Me"} onClick={""}>
            <ListItemIcon><InboxIcon color="Secondary"/></ListItemIcon>
            <ListItemText primary="Contact Me" />
          </ListItem>
        </Button>
      </List>
      <Divider />
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
        <AppBar style={{ width: '100%', background: 'transparent', boxShadow: 'none'}}>
            
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="Primary" aria-label="menu"
            onClick={toggleDrawer('left', true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)} classes={{ paper: classes.paper }}>
              {list('left')}
            </Drawer>
          
            <Typography variant="h6" className={classes.title} color='Primary'>
              Menu
            </Typography>

            
          </Toolbar>
          
        </AppBar>
    </ThemeProvider>
  );
}
