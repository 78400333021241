import React from 'react';
import ReactDOM from 'react-dom';

import TitleBar from '../home/TitleBar';
import ContactBlurb from './ContactBlurb';
import Copyright from '../home/Copyright';

import { CssBaseline, Paper, CardMedia } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const topTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#CCD6F6',
    },
    secondary: {
      main: '#8892B0',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00A8E8',
    },
    secondary: {
      main: '#00A8E8',
    },
    initial: {
      main: '#697490'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

topTheme.typography.h5 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [topTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

let divStyle = { 
  //backgroundImage:`url(${backdrop})`,
  backgroundColor: '#000709',
  //backgroundSize: 'cover',
  height: '100vh',
  width: '100vw',
  position: 'relative'
};


var desktopStyle = {
  paddingLeft: '25vw',
  paddingRight: '25vw',
  paddingTop: '15vh',
};

var mobileStyle = {
  paddingLeft: '5vw',
  paddingRight: '5vw',
  paddingTop: '15vh',
};


var bg = {
    position: 'absolute',
    bottom: '0px',
    backgroundColor: divStyle.backgroundColor,
    width: '100%',
}
var copyright = {
    paddingTop: '5em'
}

function Home() {
    return(
      
      <ThemeProvider theme={theme}>
        <React.StrictMode>
            <CssBaseline>
            <div style={divStyle}>
            <TitleBar />
            <ThemeProvider theme={theme}>
            <ContactBlurb />
            </ThemeProvider>
            <div style={bg}>
            <div style={copyright}>
                <Copyright />
              </div>
            </div>
            </div>
            
            </CssBaseline>
        </React.StrictMode>
      </ThemeProvider>
    )
}

export default Home;
