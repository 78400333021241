import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PictureFrame from "react-rounded-image";
import Tooltip from '@material-ui/core/Tooltip';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

var divStyle = {
  marginBottom: 100
};

var titleStyle = {
  paddingTop: '15rem',
  paddingLeft: '10px',
  paddingRight: '10px'
};

var imageStyle = { 
  marginBottom: 15,
  padding: 15,
  display: 'inline-block'
};

export default function ContactBlurb() {
  
    return (
        <div style={divStyle} align="center">
        
        <Typography style={titleStyle}variant="h2" align="center" color="Secondary" gutterBottom>
              Contact Me
          </Typography>

        <Tooltip title={<Typography color="primary">Email</Typography>}>
        <div style={imageStyle}>
        <Button color="Primary" target="_blank" href="mailto:daniel.dicesare22@gmail.com">
          <MailOutlineIcon fontSize='large'/>
        </Button>
        </div>
        </Tooltip>
        <Tooltip title={<Typography color="primary">Github</Typography>}>
        <div style={imageStyle}>
        <Button color="Primary" target="_blank" href="https://github.com/DanDiCesare">
          <GitHubIcon fontSize='large'/>
        </Button>
        </div>
        </Tooltip>
        <Tooltip title={<Typography color="primary">LinkedIn</Typography>}>
        <div style={imageStyle}>
        <Button color="Primary" target="_blank" href="https://www.linkedin.com/in/daniel-dicesare/">
          <LinkedInIcon fontSize='large'/>
        </Button>
        </div>
        </Tooltip>



      </div>
    );
  }