import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CardMedia } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Fade from 'react-reveal/Fade';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

//pictures
import SafeWayz from './pictures/software/safewayz.png';
import Convolution from './pictures/software/convolution.png';
import WebData from './pictures/software/webdata.png';
import Cowrite from './pictures/software/cowrite.png';
import Unity from './pictures/software/unity.png';
import RecyClean from './pictures/software/recyclean.png'

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const cards = [
    {
      title: 'SafeWayz',
      lang: 'Java',
      year: '2nd',
      img: SafeWayz,
      demo: "https://youtu.be/RhYXbICj1lg",
      src: "https://github.com/DanDiCesare/SafeWayz",
      description: [
      'An android app utilizing crime data and pathing algorithms for safe navigation.'
      ],
    },
    {
      title: 'Image Convolution',
      lang: 'C',
      year: '2nd',
      img: Convolution,
      demo: "https://github.com/DanDiCesare/Image-Convolution",
      src: "https://github.com/DanDiCesare/Image-Convolution",
      description: [
      'A command line program to apply image filter to .ppm (P3) images. Supports custom filter kernels.'
      ],
    },
    {
      title: 'Baja Web Data',
      lang: 'Python',
      year: '2nd',
      img: WebData,
      demo: "https://github.com/DanDiCesare/BajaWebData",
      src: "https://github.com/DanDiCesare/BajaWebData",
      description: [
      'Live racing data for use by drivers. Designed for future analytics improvements.'
      ],
    },
    {
      title: 'Cowrite',
      lang: 'React',
      year: '2nd',
      img: Cowrite,
      demo: "https://cowrite.io/prompts/all",
      src: "https://github.com/sikidamjanovic/cowrite",
      description: [
      'A platform that allows users to write stories, chapter-by-chapter, collaboratively!'
      ],
    },
    {
      title: 'UnityGravity',
      lang: 'C#',
      year: '2nd',
      img: Unity,
      demo: "https://dandicesare.github.io/UnityGravityDemo/",
      src: "https://github.com/DanDiCesare/UnityGravity",
      description: [
      'Demo displaying my testing of physics interactions using Unity3D.'
      ],
    },
    {
      title: 'RecyClean',
      lang: 'Python',
      year: '2nd',
      img: RecyClean,
      demo: "https://devpost.com/software/smart-materials-sort",
      src: "https://github.com/RecyClean/RecyClean",
      description: [
      'Utilizing a customizable deep learning model to categorize the materials of waste projects.'
      ],
    },
  ];

export default function Cards() {
    const classes = useStyles();
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {/* Hero unit */}
          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {cards.map((cards) => (
                <Grid item key={cards.title} xs={12} sm={6} md={4}>
                  <Fade left>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={cards.img}
                      title={cards.title}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {cards.title}
                      </Typography>
                      <Typography component="h2">
                        {cards.lang} | {cards.year} year
                      </Typography>
                      <Typography>
                        {cards.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary" target="_blank" href={cards.demo}>
                        Demo
                      </Button>
                      <Button size="small" color="primary" target="_blank" href={cards.src}>
                        Code
                      </Button>
                    </CardActions>
                  </Card>
                  </Fade>
                </Grid>   
              ))}
            </Grid>
          </Container>
        </main>
      </React.Fragment>
    );
  }

