import React from 'react';
import ReactDOM from 'react-dom';

import Copyright from '../home/Copyright';
import TitleBar from '../home/TitleBar';
import ScrollNav from './scrollnav';

import { CssBaseline, Paper, CardMedia } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ebb734',//yellow
      light: '#5ceb34'//green
    },
    secondary: {
      main: '#d62828',//red
    },
    background: {
      paper: '#d62828',//red
    }
  },
});

theme.typography.h5 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};


function Home() {
  
    return(
        <ThemeProvider theme={theme}>
            <React.StrictMode>
                <CssBaseline>
                    <TitleBar />
                    <ScrollNav />
                </CssBaseline>
            </React.StrictMode>
        </ThemeProvider>
    )
}

export default Home;
