import React from 'react';
import './App.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Me from './me_cropped.jpg';
import Computer from './computer.png';
import Magarvey from './magarveylab.png';
import Entos from './entos-logo.jpeg';
import Projects from './projects.jpg';
import PictureFrame from "react-rounded-image";
import Box from '@material-ui/core/Box';
import Fade from 'react-reveal/Fade';
import Swing from 'react-reveal/Swing';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


var imageStyle = { 
  marginBottom: 15
};

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00A8E8',
      },
      secondary: {
        main: '#F5F5F5',
      },
      initial: {
        main: '#697490'
      }
    },
  });

function HomeBottom() {
  
    return (
        <ThemeProvider theme={theme}>
            {/* About Me */}
            <Fade left>
                <Box display="fixed" align="center" flexDirection="row" p={1} m={1} bgcolor="transparent">
                    <Box p={1}>
                        <div style={imageStyle}>
                            <PictureFrame
                                image={Me}
                                roundedColor='black'
                                roundedSize="10"
                                imageWidth="200"
                                imageHeight="200"
                            /> 
                        </div>
                    </Box>
                    <Box p={1}>
                        <Typography variant="h4" color='primary'>
                            About Me
                        </Typography>
                        <br />
                        <Typography variant="subtitle1" color='secondary'>
                            I am currently in my fourth year of Software and Biomedical Engineering at McMaster University.
                            Going into university, my goal was to better the lives of others through innovative software applications in
                            the medical field. Recently, I have been working to apply deep learning models in the fields of pharmaceutical
                            development and bioinformatics.
                        </Typography>
                    </Box>
                </Box>
            </Fade>

            <br />
            <br />
            <br />

            {/* Skills */}
            <Fade right>
                <Box display="fixed" align="center" flexDirection="row-reverse" p={1} m={1} bgcolor="transparent">
                    <Box p={1}>
                        <div style={imageStyle}>
                            <PictureFrame
                                image={Computer}
                                roundedColor="black"
                                roundedSize="10"
                                imageWidth="200"
                                imageHeight="200"
                            /> 
                        </div>
                    </Box>
                    
                    <Box p={1}>
                        <Typography variant="h4" color='primary'>
                            Skills
                        </Typography>
                        <br />
                        <Typography variant="subtitle1" align="center" color='secondary'>
                            <b>Python</b><br></br>
                            <i>Machine learning (PyTorch), data science, scripting, and web development.</i>
                            <br></br><br></br>
                            <b>C/C++</b><br></br>
                            <i>Image processing, scientific computation and game development.</i>
                            <br></br><br></br>
                            <b>Javascript</b><br></br>
                            <i>Full stack development - HTML + CSS + React.</i>
                            <br></br><br></br>
                            <b>Java</b><br></br>
                            <i>Android development, graph traversal and algorithm implementations.</i>
                            <br></br><br></br>
                            <b>C#</b><br></br>
                            <i>Scripting experience in Unity3D</i>
                        
                        </Typography>
                    </Box>
                    
                </Box>
            </Fade>

            <br />
            <br />
            <br />

            {/* Experience */}
            <Fade left>
                <Box display="fixed" align ="center" flexDirection="row" p={1} m={1} bgcolor="transparent">
                    <Box p={1}>
                        <div style={imageStyle}>
                            <PictureFrame
                                image={Entos}
                                roundedColor="black"
                                roundedSize="10"
                                imageWidth="200"
                                imageHeight="200"
                            /> 
                        </div>
                    </Box>
                    
                    <Box p={1}>
                        <Typography variant="h4" color='primary'>
                            Entos
                        </Typography>
                        <br />
                        <Typography variant="body1" color='secondary'>
                            <b>Machine Learning Intern - Small-Molecule Development</b>
                        </Typography>
                        <Typography variant="subtitle1" align="center" color='secondary'>

                                | Machine learning tools for pharmaceutical development |<br />
                                | NLP generative and classifier models |<br />
                                | Research and development |

                        </Typography>
                    </Box>     
                </Box>
            </Fade>
            <Fade left>
                <Box display="fixed" align ="center" flexDirection="row" p={1} m={1} bgcolor="transparent">
                    <Box p={1}>
                        <div style={imageStyle}>
                            <PictureFrame
                                image={Magarvey}
                                roundedColor="black"
                                roundedSize="10"
                                imageWidth="200"
                                imageHeight="200"
                            /> 
                        </div>
                    </Box>
                    
                    <Box p={1}>
                        <Typography variant="h4" color='primary'>
                            Magarvey Lab
                        </Typography>
                        <br />
                        <Typography variant="body1" color='secondary'>
                            <b>Machine Learning Research Assistant</b>
                        </Typography>
                        <Typography variant="subtitle1" align="center" color='secondary'>

                                | BERT-based genomic sequence classification |<br />
                                | Custom model architectures for metabolomic analysis |<br />
                                | Graph learning for drug discovery |

                        </Typography>
                    </Box>     
                </Box>
            </Fade>

            <br />
            <br />
            <br />
        
        </ThemeProvider>
    );
  }

export default HomeBottom;


