import React from 'react';
import ReactDOM from 'react-dom';

import TitleBar from './TitleBar';
import TitleBlurb from './TitleBlurb';
import Copyright from './Copyright';
import Cards from './cards';
import HomeBottom from './HomeBottom';
import HomeBanner from './HomeBanner';
import backdrop from './lowpoly.png';
import ScrollIcon from './ScrollIcon';
import * as serviceWorker from './serviceWorker';

import { CssBaseline, Paper, CardMedia } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import Reveal from 'react-reveal/Reveal';
import MediaQuery from 'react-responsive';
import Scroll from 'react-scroll';
import IconButton from '@material-ui/core/IconButton';

const topTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#007EA7',
    },
    secondary: {
      main: '#B5B9BA',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00A8E8',
    },
    secondary: {
      main: '#00A8E8',
    },
    initial: {
      main: '#00A8E8'
    }
  },
});

topTheme.typography.h5 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [topTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

let divStyle = { 
  //backgroundImage:`url(${backdrop})`,
  backgroundColor: '#000709',
  //backgroundSize: 'cover',
  height: '100vh',
  width: '100%',
  position: 'relative'
};

let arrowStyle = {
  width: '100%',
  height: '50px',
  position: 'absolute',
  bottom: '3vh'
}

var desktopStyle = {
  paddingLeft: '25vw',
  paddingRight: '25vw',
  paddingTop: '15vh',
};

var mobileStyle = {
  paddingLeft: '5vw',
  paddingRight: '5vw',
  paddingTop: '15vh',
};

var copyright = {
  paddingTop: '5em',
    paddingBottom: '2em'
}

var bg = {
  backgroundColor: divStyle.backgroundColor,
}

function Home() {
    return(
      
      <ThemeProvider theme={theme}>
        <React.StrictMode>
            <CssBaseline>
            <div style={divStyle}>
            <TitleBar />
            <ThemeProvider theme={topTheme}>
            <TitleBlurb />
            </ThemeProvider>
            <div style={arrowStyle} align="center">
            <ScrollIcon />
            </div>
            </div>

            <div style={bg}>
              <MediaQuery minDeviceWidth={1224}>
                <div style={desktopStyle}>
                  <HomeBottom />
                </div>
              </MediaQuery>

              <MediaQuery maxDeviceWidth={1224}>
                <div style={mobileStyle}>
                  <HomeBottom />
                </div>
              </MediaQuery>

              <HomeBanner />
              <div style={copyright}>
                <Copyright />
              </div>
            </div>
            </CssBaseline>
        </React.StrictMode>
      </ThemeProvider>
    )
}

export default Home;
